import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFD700',
    },
    secondary: {
      main: '#ff855c',
    },
  },
});

export default theme;

// #0F5FD1 blue [Secondary]
// #1AACC5 lt ble
// #0D91DB blue teal
// #0FDCC9 teal
// #z grean [Primary]