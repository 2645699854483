import { ReadableAppPage } from "./Components/AppPage";
import ScheduleItem from "./Components/ScheduleItem";

const Schedule = () => (
  <ReadableAppPage includeHeader currentPage="Schedule">
    <div className="schedule-date">June 21, 2024</div>
    <p className="schedule-location">
      <a href="https://maps.app.goo.gl/ZcoJWT5fokVu56kL9" target="_blank" rel="noreferrer">
      Valley View Farm <br/>
      16 Walpole Road <br />
      Haydenville, MA <br />
      </a>
    </p>
    <ScheduleItem
      time="4 PM"
      label="Ceremony"
      location={
        <div>
          under the hickory tree
        </div>
      }
      side="left"
    />
    <ScheduleItem
      time="4:30 PM"
      label="Cocktail Hour"
      location={
        <div>
          the patio
        </div>
      }
      side="right"
    />
    <ScheduleItem
      time="5:30 PM"
      label="Dinner"
      location={
        <div>
          the Yellow Barn
        </div>
      }
      side="left"
    />
    <ScheduleItem
      time="10:00 PM"
      label="Carriages"
      side="right"
    />
  </ReadableAppPage>
);

export default Schedule;
