import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AppPage } from "./Components/AppPage";

const Home = () => (
  <AppPage className="home">
    <Box className="logo logo-with-shadow">
      <b>Nils</b>
      <b>&</b>
      <b>Amanda</b>
    </Box>
    <Box className="details">
      <b>June 21, 2024</b>
      <div className="homeButtons">
      <Button
        component={RouterLink}
        variant="outlined"
        size="large"
        to="/about"
        color="primary"
      >
        Details
      </Button>
      <Button
        component={RouterLink}
        variant="outlined"
        size="large"
        to="/rsvp"
        color="primary"
      >
        RSVP
      </Button>
      </div>
    </Box>
  </AppPage>
);

export default Home;
