import { Container, ThemeProvider } from "@mui/material";
import theme from "../../theme";
import Stars from "../Stars";
import Header from "../Header";


const AppPage = (props) => (
  <ThemeProvider theme={theme}>
    <Stars />
    <div className="cats-wrapper">
      <div className="sammy cat" />
      <div className="gracie cat" />
    </div>
    <div className={props.className ?? ""}>
      {props.includeHeader && <Header currentPage={props.currentPage} /> }
      <div className="content-wrapper">
        <Container className="homebody">
          {props.children}
        </Container>
      </div>
    </div>
  </ThemeProvider>
);

export default AppPage;
