
const ScheduleItem = (props) => (
    <div className={`schedule-item schedule-item-${props.side}`}>
        <div>
            <div className="schedule-label">
                {props.label}
            </div>
            <div className="schedule-time">
                {props.time}
            </div>
        </div>
        <div className="schedule-item-divider"></div>
        <div>
            <div className="schedule-location">
                {props.location}
            </div>
            {/* <div className="schedule-description">
                {props.description}
            </div> */}
        </div>
    </div>
);

export default ScheduleItem;
